import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks'
import { Link } from 'preact-router/match';
import { route } from 'preact-router';
import { useQuery, useMutation } from '@apollo/client'

import q from '../q'

const Header = ({ setPid, pid, userClear }) => {
	const { data: { getMyProfile } = {} } = useQuery(q.Q_GET_PROFILE)
	const [setProfileM, { data }] = useMutation(q.M_SET_PROFILE);

	const [dark, setDark] = useState()

	const projects = getMyProfile?.user?.projects?.edges //user?.projects?.edges
	const project = projects ? projects.filter(i => i.id === pid)[0] : false

	const taskAdd = e => {
		e.preventDefault()
		route('/task/new/' + Math.floor(+ new Date() / 1000))
	}

	const setProject = (pid2) => {
		if (pid === pid2) return
		// console.log('- setProject ->' + pid2)
		localStorage.setItem('pid', pid2)
		setPid(pid2)
		setProfileM({ variables: { project: pid2 } })
	}

	const setDarkTheme = (e) => {
		// console.log('e', e)
		const dark = e?.target?.checked
		// console.log('dark ->', dark)
		localStorage.setItem('dark', dark)
		setDark(dark)
		setProfileM({ variables: { dark } })
		if (window) window.document.body.className = dark ? 'dark' : 'light'
	}

	useEffect(() => {
		if (window) {
			let d = localStorage.getItem('dark')
			setDark(d)
			window.document.body.className = d ? 'dark' : 'light'
		}

		if (getMyProfile?.user) {
			const { user, dark, last_project } = getMyProfile
			setPid(last_project)
			setDark(dark)

			console.log(user)

			if (typeof dark === 'boolean') {
				localStorage.setItem('dark', dark)
				if (window) window.document.body.className = dark ? 'dark' : 'light'
			}

			if (last_project) {
				localStorage.setItem('pid', last_project)
			}
		}
	}, [getMyProfile])

	return <nav class="navbar navbar-expand-lg navbar-light">
		{(projects && projects.length > 0) ?
			(
				(projects && projects.length === 1 && project) ?
					<Link class="navbar-brand text-info" href="/">{project.title}</Link>
					:
					<a class="nav-item dropdown">
						<a class="nav-link dropdown-toggle text-info" href="#" id="navProjects" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
							{project ? project.title : 'No project'}
						</a>
						<div class="dropdown-menu" aria-labelledby="navProjects">
							{projects.map(p =>
								<a onClick={() => setProject(p.id)} class="dropdown-item" href="#">{p.title}</a>
							)}
						</div>
					</a>
			)
			:
			<Link class="navbar-brand" href="/">Tasker</Link>
		}

		<button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
			<span class="navbar-toggler-icon"></span>
		</button>
		<div class="collapse navbar-collapse justify-content-start" id="navbarNavDropdown">

			<ul class="navbar-nav">
				{getMyProfile?.user?.role === 0 &&
					<li class="nav-item">
						<Link class="nav-link" href="/admin">Admin</Link>
					</li>
				}

				{projects && projects.length > 0 && <li class="nav-item">
					<Link class="nav-link" href="/tasks">Tasks</Link>
				</li>}

				{projects && projects.length > 0 && <li class="nav-item">
					<Link class="nav-link" href="/">My Tasks</Link>
				</li>}

				{projects && projects.length > 0 &&
					<li class="nav-item">
						<a onClick={taskAdd} href="#" class="btn btn-sm btn-primary ml-1 mt-1">+ task</a>
					</li>
				}
			</ul>
		</div>

		<div class="collapse navbar-collapse justify-content-end" id="navbarNavDropdown">

			<div class="custom-control custom-switch mt-3 mt-lg-0 mt-xl-0 mr-3">
				<input type="checkbox" class="custom-control-input" id="dark" onChange={setDarkTheme} defaultChecked={dark} />
				<label class="custom-control-label" for="dark">dark</label>
			</div>

			<ul class="navbar-nav">
				<li class="nav-item dropdown">
					<a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						{getMyProfile?.user?.name}
					</a>
					<div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdownMenuLink">
						{getMyProfile?.user?.id && <Link class="dropdown-item" href={"/user/" + getMyProfile?.user?.id}>Profile</Link>}
						<a class="dropdown-item" href="#" onClick={userClear}>Logout</a>
					</div>
				</li>
			</ul>
		</div>

	</nav >
}

export default Header