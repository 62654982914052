import { h } from 'preact';
import { Router, route } from 'preact-router';
import { useState } from 'preact/hooks';
import { ApolloProvider, useQuery, useLazyQuery } from '@apollo/client';

import Header from './components/header.js';
import User from './routes/user.js';
import Login from './routes/login.js';
import Registration from './routes/registration.js';
import Redirect from './components/redirect.js';
import Admin from './routes/admin.js';
import Project from './routes/project.js';
import Tasks from './routes/tasks.js';
import TaskNew from './routes/tasknew.js';
import TaskEdit from './routes/taskedit.js';

import q from './q';

const App = (props) => {

	const [pid, setPid] = useState(localStorage.getItem('pid'))
	const [token, setToken] = useState(localStorage?.getItem('token'))

	// const [setProfile, { data: {getMyProfile: {user} = {} } = {} }] = useLazyQuery(q.Q_GET_PROFILE)

	// token && setProfile();

	const userClear = () => {
		localStorage.removeItem('token');
		localStorage.clear()
		setToken(null)
		props.link.subscriptionClient.close(false, false)
		// setToken(null)
		setTimeout(() => window.location.href = '/login', 100)
	}

	const handleRoute = (e) => {
		setTimeout(() => {
			if (token || localStorage.getItem('token')) { // loged in

				if (e.url === '/login') return route('/tasks');

			} else { // not loged
				localStorage.removeItem('token');
				if (e.url !== '/login' && e.url !== '/registration') return route('/login', true);
			}
		}, 500)
	}

	return (
		<ApolloProvider client={props.client} >
			<div id="app" class="">
				{token ?
					<div>
						<Header
							setPid={setPid}
							client={props.client}
							pid={pid}
							setPid={setPid}
							userClear={userClear}
						/>
						<div className="container-fluid mt-3">
							<Router onChange={handleRoute}>
								<Tasks path={'/'} client={props.client} filter={user => i => i?.assigned?.id === user?.id} pid={pid} />
								<Tasks path={'/my'} client={props.client} filter={user => i => i?.assigned?.id === user?.id} pid={pid} />
								<Tasks path={'/tasks'} client={props.client} pid={pid} />

								<TaskNew path="/task/new/:time" client={props.client} pid={pid} />
								<TaskEdit path="/task/edit/:id" client={props.client} pid={pid} />

								<Admin path="/admin" client={props.client} />

								<Project path="/project/:id" client={props.client} />
								<User client={props.client} path="/user/:id" />
							</Router>
						</div>
					</div>
					:
					<Router onChange={handleRoute}>
						<Login path="/login" client={props.client} link={props.link} setToken={setToken} />
						<Registration path="/registration" link={props.link} client={props.client} setToken={setToken} />
						<Redirect path="/" to="/login" />
					</Router>
				}
			</div>
		</ApolloProvider>
	)
}

export default App