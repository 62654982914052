import "../node_modules/bootstrap/dist/js/bootstrap.min.js";
import './style'
import { ApolloProvider, ApolloClient, InMemoryCache } from '@apollo/client';
import { WebSocketLink } from '@apollo/client/link/ws'

import App from './app'

const host = window?.location?.hostname || 'localhost'
const uri = host === 'localhost' ? 'ws://localhost:3031/graphql' : `wss://${host}/graphql`

const link = new WebSocketLink({
    uri,
    //'wss://tasker.dcxv.com/graphql',
    options: {
        //lazy: true,
        reconnect: true,
        timeout: 30000,
        connectionParams: () => ({
            token: localStorage.getItem('token')
        })
    }
});

const client = new ApolloClient({
    link,
    cache: new InMemoryCache()
})

const Root = () => (
    <ApolloProvider client={client}>
        <App link={link} client={client} />
    </ApolloProvider>
)

export default Root
