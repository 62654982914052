import gql from 'graphql-tag'

export default {
    M_SIGNIN: gql`
    mutation Login($l: String!, $p: String!) {
        userLogin(login: $l, pw: $p) {
            ... on Error {
                error
            }
            ... on User {
                id
                token
                created
                role
                name
                login
                projects {
                    total
                    edges {
                        id
                        created
                        title
                    }
                }
            }
        }
    }`,
    M_SIGNUP: gql`
    mutation userReg($name: String!, $login: String!, $pw: String!) {
        userReg(name: $name, login: $login, pw: $pw) {
            id
            token
            created
            role
            name
            login
        }
    }`,

    Q_GET_PROFILE: gql`
    query {
        getMyProfile {
            user {
                id
                token
                created
                role
                name
                login
                projects {
                    total
                    edges {
                        id
                        created
                        title
                    }
                }
            }
            last_project
            dark
        }
    }
    `,

    M_SET_PROFILE: gql`
    mutation setMyProfile($dark: Boolean, $project: String) {
        setMyProfile(dark: $dark, project: $project) {
            user {
                id
                token
                created
                role
                name
                login
                projects {
                    total
                    edges {
                        id
                        created
                        title
                    }
                }
            }
            last_project
            dark
        }
    }
    `,


    Q_GET_USER: gql`
    query getUser($id: ID!) {
        getUser(id: $id) {
            id
            token
            created
            role
            name
            login
            projects {
                total
                edges {
                    id
                    created
                    title
                }
            }
        }
    }
    `,

    Q_GET_PROJECT: gql`
    query getProject($id:ID!) {
        getProject(id:$id) {
            id
            created
            updated
            tasks
            title
            users {
                total
                edges {
                    id
                    role
                    name
                }
            } 	
            conf {
                token
            } 
        } 
    }
    `,

    Q_GET_PROJECTS: gql`
    query {
        getProjects {
            total
            edges {
                id
                title
            }
        }
    }
    `,

    Q_GET_PROJECTS_AND_USERS_FULL: gql`
    query {
        getProjects {
            total
            edges {
                id
                title
            }
        }
        getUsers {
            total
            edges {
            id
            created
            role
            name
            login
            projects {
                total
                edges {
                    id
                    title
                    }
                }
            }
        }
    }
    `,

    M_PROJECT_ADD: gql`
    mutation projectAdd($title: String!) {
        projectAdd(title: $title) {
            id
            created
            title
        }
    }
    `,

    Q_GET_USERS: gql`
    query {
        getUsers {
         total
         edges {
           id
           name
         }
       }
    }`,

    Q_GET_USERS_FULL: gql`
    query {
        getUsers {
            total
            edges {
            id
            created
            role
            name
            login
            projects {
                total
                edges {
                    id
                    title
                    }
                }
            }
        }
    }
    `,

    M_PROJECT_MOD: gql`
    mutation projectMod($id:ID!, $title: String, $users:[String]) {
        projectMod(id: $id, title: $title, users: $users) {
            id
            updated
            title
            users {
                total
                edges {
                    id
                    name
                }
            }
        }
    }`,

    Q_GET_TASKS: gql`
    query getTasks($pid: ID!, $after: String, $sortBy: String) {
        getTasks(project: $pid, after: $after, sortBy: $sortBy) {
            total
            edges {
                id
                created
                updated
                author {
                    id
                    name
                }
                assigned {
                    id
                    name
                }
                title
                msg
                estim
                spent
                prio
                progress
                status
            }
        }
    }`,

    Q_GET_TASK: gql`
    query getTask($id: ID!) {
        getTask(id: $id) {
            id
            created
            updated
            author {
                id
                name
            }
            assigned {
                id
                name
            }
            title
            msg
            estim
            spent
            prio
            progress
            status
            comments {
                id
                created
                uid
                name
                msg
            }
        }
    }`,

    M_TASK_ADD: gql`
    mutation taskAdd($project: String!, $title: String!, $msg: String!, $assigned: String!, $estim: Float!, $spent: Float!, $prio: Int!, $progress: Int!, $status: Int!) {
        taskAdd(project:$project, title: $title, msg: $msg, assigned: $assigned, estim: $estim, spent: $spent, prio: $prio, progress: $progress, status: $status) {
            id
            created
            author {
                id
                name
            }
            assigned {
                id
                name
            }
            title
            msg
            spent
            estim
            prio
            progress
            status
        }
    }`,

    M_TASK_MOD: gql`
    mutation taskMod($id: ID!, $project: String, $author: String, $assigned: String, $title: String, $msg: String, $estim: Float, $spent: Float, $prio: Int, $progress: Int, $status: Int) {
        taskMod(id: $id, project: $project, author: $author, assigned: $assigned, title: $title, msg: $msg, estim: $estim, spent: $spent, prio: $prio, progress: $progress, status: $status) {
            id
            created
            author {
                id
                name
            }
            assigned {
                id
                name
            }
            title
            msg
            spent
            estim
            prio
            progress
            status
        }
    }`,

    M_TASK_DEL: gql`
    mutation taskDel($id: ID!) {
        taskDel(id: $id)
    }
    `,

    M_USER_ADD: gql`
    mutation userAdd($role: Int!, $name: String!, $login: String!, $pw: String!) {
        userAdd(role: $role, name: $name, login: $login, pw: $pw) {
            id
            created
            role
            name
            login
            projects {
            total
            edges {
                    id
                    title
                }
            }
        }
    }
    `,

    M_USER_MOD: gql`
    mutation userMod($id: ID!, $role: Int, $name: String, $login: String, $pw: String) {
        userMod(id: $id, role: $role, name: $name, login: $login, pw: $pw) {
            id
            created
            role
            name
            login
            projects {
            total
            edges {
                    id
                    title
                }
            }
        }
    }
    `,

    M_USER_DEL: gql`
    mutation userDel($id: ID!) {
        userDel(id: $id)
    }`,

    M_COMMENT_ADD: gql`
    mutation commentAdd($task: ID!, $msg: String!) {
        commentAdd(task:$task, msg: $msg) {
            id
            comments {
                id
                created
                uid
                name
                msg
            }
        }
    }`,

    M_DEL_PROJECT: gql`mutation ($id: ID!) {
        projectDel(id: $id)
     }`,

    M_USER_MOD: gql`mutation ($id: ID!, $role: Int, $name: String, $login: String, $pw: String){
        userMod(id: $id, role: $role, name: $name, login: $login, pw: $pw){
            id
        }
    }`,

    status: ['on hold', 'todo', 'in progress', 'to verify', 'done', 'question'],
    prio: ['low', 'normal', 'high', 'critical'],
    role: ['admin', 'editor', 'viewer']
}